import React, { Component } from 'react'
import { Field, Fields, reduxForm, getFormSyncErrors, hasSubmitFailed } from 'redux-form'
import { compose } from 'redux';
import { connect } from 'react-redux'
import { Row, Col, Modal, Button, Icon, notification } from 'antd'
import fieldConfig from './fieldconfig'
import './form.css';

function getField(props) {
  if (props.names) {
    return <Fields {...props} />
  }
  return <Field {...props} />
}

// let JobForm = props => {
export class JobForm extends Component {
  state = {
    openModal: false,
    submitFailed: false,
    triggerSubmit: null,
    firstTimeCheck: true,
  }

  formIncomplete = () => {
    const { openModal, firstTimeCheck } = this.state;

    if (!openModal) {
      if (firstTimeCheck) {
        this.setState({ openModal: true, firstTimeCheck: false });
      } else {
        this.setState({ openModal: true });
      }
    }
  }

  errorMessage = () => {
    const { handleSubmit, industries, employment, keywords, submitFailed, syncErrors, triggerSubmit, anyTouched } = this.props;
    notification.error({
      message: `Please fill up the required field`,
      description: (
        <ul style={{ color: 'red', marginLeft: '-1em' }}>
          {fieldConfig({ industries, employment, keywords}).map(({ name, label }) => {
            if (Object.keys(syncErrors).includes(name)) {
              return (
                <li>{label}</li>
              )
            }
          })}
        </ul>
      ),
    });
  }

  handleOk = (e) => {
    this.setState({
      openModal: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      openModal: false,
    });
  }

  render() {
    const { handleSubmit, industries, employment, keywords, submitFailed, syncErrors, triggerSubmit, anyTouched } = this.props;
    const { firstTimeCheck, openModal } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        {(anyTouched && submitFailed && firstTimeCheck) && this.formIncomplete()}
        {(triggerSubmit && submitFailed) && this.formIncomplete()}
        <Row gutter={16}>
          {fieldConfig({ industries, employment, keywords}).map(({ layout, ...rest }) => (
            <Col className={`text-align-left ${rest.parentClassName}`} key={rest.name} {...layout}>
              {rest.excludeFromForm ?
                rest.component
              :
              getField(rest)}
            </Col>
          ))}
        </Row>
        {/*openModal && this.errorMessage()*/}
        <Modal
          className="jobFormErrorModal"
          title={(
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon
                type="close-circle"
                style={{
                  color: 'red',
                  fontSize: '22px',
                  marginRight: '16px',
                }}
              />
              Please fill up the required field
            </div>
          )}
          visible={openModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          closable={false}
          destroyOnClose={true}
          centered={true}
          footer={[
            <Button style={{ display: 'none' }} />,
            <Button style={{ border: 'none' }} key="submit" type="primary" onClick={this.handleOk}>
              Ok
            </Button>
          ]}
        >
          <ul style={{ color: 'red' }}>
            {fieldConfig({ industries, employment, keywords}).map(({ name, label }) => {
              if (Object.keys(syncErrors).includes(name)) {
                return (
                  <li>{label}</li>
                )
              }
            })}
          </ul>
        </Modal>
      </form>
    );
  }
}

const enhanced = compose(
  connect((state, props) => {
    const { record, submit } = props;
    if (record) {
      return {
        initialValues: record,
        onSubmit: submit,
      };
    }
    return {};
  }),
  reduxForm({
    form: 'job',
    enableReinitialize: true,
  }),
  connect(state => {
    const syncErrors = getFormSyncErrors('job')(state);
    return { syncErrors };
  }),
);
export default enhanced(JobForm);
