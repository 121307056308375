import './dashboard.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'antd';
import EventCard from './components/EventCard';

const data = [
  {
    title: 'Event Title 1',
  },
  {
    title: 'Event Title 2',
  },
  {
    title: 'Event Title 3',
  },
  {
    title: 'Event Title 4',
  },
  {
    title: 'Event Title 5',
  },
  {
    title: 'Event Title 6',
  },
];

export class Dashboard extends Component {
  render() {
    // const useless = null;
    // const moreUseless = null;

    return (
      <div className="dashboard">
        <List
          grid={{
            gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4,
          }}
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <EventCard index={index} {...item} />
            </List.Item>
          )}
        />
      </div>
    );
  }
}

export default connect(null, null)(Dashboard);
