import React from 'react';
import { Field, Fields, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'antd';
import { SketchPicker } from 'react-color';
import fieldConfig from './fieldconfig';

const FormItem = Form.Item;

function getField(props) {
  if (props.names) {
    return <Fields {...props} />
  }
  return <Field {...props} />
}

let EditModal = props => {
  const { handleSubmit, siteColor, displayColorPicker, handleColorClick, handleColorClose, handleColorChange } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={16}>
        {fieldConfig().map(({ layout, ...rest }) => (
          <Col key={rest.name} {...layout}>
            {rest.excludeFromForm ?
              <FormItem className="manual-item-required" label="Color">
                <div>
                  <div className="swatch" onClick={ handleColorClick }>
                    <div className="site_color" style={{ background: `rgba(${ siteColor.r }, ${ siteColor.g }, ${ siteColor.b }, ${ siteColor.a })` }} />
                  </div>
                  { displayColorPicker ? <div className="popover">
                    <div className="site_cover" onClick={ handleColorClose }/>
                    <SketchPicker color={ siteColor } onChange={ handleColorChange } />
                  </div> : null }
                </div>
              </FormItem>
            :
              getField(rest)
            }
          </Col>
        ))}
      </Row>
    </form>
    );
}

const enhanced = compose(
  connect((state, props) => {
    const { record, submit, siteColor, displayColorPicker, handleColorClick, handleColorClose, handleColorChange } = props;

    if (record) {
      return {
        initialValues: record,
        onSubmit: submit,
        siteColor: siteColor,
        displayColorPicker: displayColorPicker,
        handleColorClick: handleColorClick,
        handleColorClose: handleColorClose,
        handleColorChange: handleColorChange,
      };
    }
    return {};
  }),
  reduxForm({
    form: 'city',
    enableReinitialize: true,
  })
);
export default enhanced(EditModal);
