export const required = value => (value || typeof value === 'number' ? undefined : 'Required');

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength70 = maxLength(70);

export const maxLength250 = maxLength(250);

export const completeCity = values => {
  console.log(values);
  return 'no good...';
}
