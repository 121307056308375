import './auth.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from './actions';
import LoginForm from './components/login';
import { Row, Col } from 'antd';

export class Auth extends Component {
  render() {
    const { login, } = this.props;

    return (
      <div className="auth" style={{ minHeight: '100vh' }}>
        <Row>
          <Col span={9} />
          <Col span={6}>
            <h2 style={{ textAlign: 'center' }}>Admin Login</h2>
            <LoginForm login={login} />
          </Col>
          <Col span={9} />
        </Row>
      </div>
    );
  }
}

const actions = {
  login,
};

export default connect(null, actions)(Auth);
