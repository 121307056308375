import React from 'react';
import { Modal, Table, Row, Input, Button, Popconfirm, Icon, Col, Pagination } from 'antd';
import MediaQuery from 'react-responsive';
import moment from 'moment';

export default function JobTable({
  dataSource,
  handleSearch,
  handleReset,
  acceptJob,
  rejectJob,
  editEntry,
  deleteEntry,
  showModalMob,
  closeModal,
  visible,
  editableRecord,
}) {
  let searchInput;

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 20,
      render: (text, record) => (
        <span>
          {record.featured === 1 &&
            <Icon type="flag" />
          }
        </span>
      ),
    }, {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => (searchInput = ele)}
            placeholder="Search title"
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={handleSearch(selectedKeys, confirm)}
          />
          <Button
            type="primary"
            onClick={handleSearch(selectedKeys, confirm)}
          >
            Search
          </Button>
          <Button onClick={handleReset(clearFilters)}>Reset</Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />
      ),
      onFilter: (value, record) => {
        if(record.title) {
          return record.title.toLowerCase().includes(value.toLowerCase());
        }
        return;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => {
            searchInput.focus();
          });
        }
      },
    }, {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => (searchInput = ele)}
            placeholder="Search author"
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={handleSearch(selectedKeys, confirm)}
          />
          <Button
            type="primary"
            onClick={handleSearch(selectedKeys, confirm)}
          >
            Search
          </Button>
          <Button onClick={handleReset(clearFilters)}>Reset</Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />
      ),
      onFilter: (value, record) => {
        if(record.author) {
          return record.author.toLowerCase().includes(value.toLowerCase());
        }
        return;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => {
            searchInput.focus();
          });
        }
      },
    }, {
      title: 'Employment',
      dataIndex: 'employment',
      key: 'employment',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => (searchInput = ele)}
            placeholder="Search employment"
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={handleSearch(selectedKeys, confirm)}
          />
          <Button
            type="primary"
            onClick={handleSearch(selectedKeys, confirm)}
          >
            Search
          </Button>
          <Button onClick={handleReset(clearFilters)}>Reset</Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />
      ),
      onFilter: (value, record) => {
        if(record.employment) {
          return record.employment.toLowerCase().includes(value.toLowerCase());
        }
        return;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => {
            searchInput.focus();
          });
        }
      },
    }, {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      render: (text) => Array.isArray(text) ? text.join(', ') : text,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => (searchInput = ele)}
            placeholder="Search industry"
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={handleSearch(selectedKeys, confirm)}
          />
          <Button
            type="primary"
            onClick={handleSearch(selectedKeys, confirm)}
          >
            Search
          </Button>
          <Button onClick={handleReset(clearFilters)}>Reset</Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />
      ),
      onFilter: (value, record) => {
        const industry = Array.isArray(record.industry) ? record.industry.join(', ') : record.industry;
        if(industry) {
          return industry.toLowerCase().includes(value.toLowerCase());
        }
        return;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => {
            searchInput.focus();
          });
        }
      },
    }, {
      title: 'Functions',
      dataIndex: 'keywords',
      key: 'function',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => (searchInput = ele)}
            placeholder="Search functions"
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={handleSearch(selectedKeys, confirm)}
          />
          <Button
            type="primary"
            onClick={handleSearch(selectedKeys, confirm)}
          >
            Search
          </Button>
          <Button onClick={handleReset(clearFilters)}>Reset</Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />
      ),
      onFilter: (value, record) => {
        if(record.employment) {
          return record.employment.toLowerCase().includes(value.toLowerCase());
        }
        return;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => {
            searchInput.focus();
          });
        }
      },
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => (searchInput = ele)}
            placeholder="Search status"
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={handleSearch(selectedKeys, confirm)}
          />
          <Button
            type="primary"
            onClick={handleSearch(selectedKeys, confirm)}
          >
            Search
          </Button>
          <Button onClick={handleReset(clearFilters)}>Reset</Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />
      ),
      onFilter: (value, record) => {
        if(record.status) {
          return record.status.toLowerCase().includes(value.toLowerCase());
        }
        return;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => {
            searchInput.focus();
          });
        }
      },
    }, {
      title: 'Published',
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      render: (text, record) => {
        let publicationDate;
        if (record.publicationDate) {
          publicationDate = record.publicationDate._seconds || record.publicationDate.seconds;
        }
        if (!publicationDate) {
          publicationDate = record.publicationDate;
        }
        publicationDate = new Date(publicationDate * 1000);
        return publicationDate ? publicationDate.toLocaleDateString() : '';
      },
    }, {
      title: 'Expiration',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (text, record) => {
        let expiryDate;
        if (record.expiryDate) {
          expiryDate = record.expiryDate._seconds || record.expiryDate.seconds;
        }
        if (!expiryDate) {
          expiryDate = record.expiryDate;
        }
        expiryDate = new Date(expiryDate * 1000);
        return expiryDate ? expiryDate.toLocaleDateString() : '';
      },
    }
  ];

  const getColumns = [
    ...columns,
    {
      title: '',
      dataIndex: '',
      key: 'x',
      fixed: 'right',
      width: 170,
      render: (text, record) => (
        <Row gutter={4}>
          <Col span={5}>
            <div>
              {record.status === 'pending' &&
                <Popconfirm
                  title="Are you sure you want to accept this job?"
                  onConfirm={e => acceptJob(record.objectID)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small" shape="circle" title="accept job" icon="check" />
                </Popconfirm>}
            </div>
          </Col>
          <Col span={5}>
            <div>
              {record.status === 'pending' &&
                <Popconfirm
                  title="Are you sure you want to reject this job?"
                  onConfirm={e => rejectJob(record.objectID)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small" shape="circle" title="reject job" icon="close" />
                </Popconfirm>}
            </div>
          </Col>
          <Col span={4}>
            <div>
              {record.status === 'published' &&
                <Popconfirm
                  title="Are you sure you want to unpublish this job?"
                  onConfirm={e => rejectJob(record.objectID)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small" shape="circle" title="unpublish this job" icon="stop" style={{ border: '0px', fontSize: '18px' }} />
                </Popconfirm>}
              {record.status === 'unpublished' &&
                <Popconfirm
                  title="Are you sure you want to publish this job?"
                  onConfirm={e => acceptJob(record.objectID)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small" shape="circle" title="publish this job" icon="check" />
                </Popconfirm>}
            </div>
          </Col>
          <Col span={5}>
            <div>
              <Button
                size="small"
                shape="circle"
                icon="edit"
                onClick={() => editEntry(record.objectID)}
              />
            </div>
          </Col>
          <Col span={5}>
            <div>
              <Popconfirm
                title="Are you sure you want to delete this job?"
                onConfirm={e => deleteEntry(record.objectID)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" shape="circle" icon="delete" />
              </Popconfirm>
            </div>
          </Col>
        </Row>
      ),
    },
  ];

  const mobGetColumns = [
    ...columns,
    {
      title: '',
      dataIndex: '',
      key: 'x',
      fixed: 'right',
      width: 50,
      render: (text, record) => (
        <Row style={{ alignItems: 'flex-end' }}>
          <span onClick={() => showModalMob(record)}>...</span>
        </Row>
      ),
    },
  ];

  return (
    <div>
      <MediaQuery query="(max-width: 599px)">
        <Table dataSource={dataSource} columns={mobGetColumns} scroll={{ x: 1300 }} pagination={{ showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }} />
      </MediaQuery>

      <MediaQuery query="(min-width: 599px)">
        <Table dataSource={dataSource} columns={getColumns} scroll={{ x: 1300 }} pagination={{ showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }} />
      </MediaQuery>

      <Modal
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        {editableRecord && editableRecord.status === 'pending' ?
          <Row gutter={4}>
            <Col span={12} style={{ textAlign: 'center'}}>
              <div>
                <Popconfirm
                  title="Are you sure you want to accept this job?"
                  onConfirm={e => acceptJob(editableRecord.objectID)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="button">Accept Job</Button>
                </Popconfirm>
              </div>
            </Col>
            <Col span={12} style={{ textAlign: 'center'}}>
              <Popconfirm
                title="Are you sure you want to reject this job?"
                onConfirm={e => rejectJob(editableRecord.objectID)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="button">Reject Job</Button>
              </Popconfirm>
            </Col>
            <br />
            <br />
          </Row>
        : null
        }

        {editableRecord && editableRecord.status !== 'pending' ?
          <Row gutter={4}>
            <Col span={24} style={{ textAlign: 'center'}}>
              {editableRecord.status === 'accepted' &&
                <Popconfirm
                  title="Are you sure you want to unpublish this job?"
                  onConfirm={e => rejectJob(editableRecord.objectID)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="button">Unpublish</Button>
                </Popconfirm>}
              {editableRecord.status === 'rejected' &&
                <Popconfirm
                  title="Are you sure you want to publish this job?"
                  onConfirm={e => acceptJob(editableRecord.objectID)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="button">Publish</Button>
                </Popconfirm>}
            </Col>
            <br />
            <br />
          </Row>
        : null
        }

        <Row gutter={4}>
          <Col span={12} style={{ textAlign: 'center'}}>
            <Button
              type="button"
              onClick={() => editEntry(editableRecord.objectID)}
            >
              Edit
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: 'center'}}>
            <Popconfirm
              title="Are you sure you want to delete this job?"
              onConfirm={e => deleteEntry(editableRecord.objectID)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="button" style={{color: 'red'}}>Delete</Button>
            </Popconfirm>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
