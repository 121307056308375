import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, withRouter } from 'react-router-dom';
import { Row, Col, Form, Input, Icon, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import firebase from '../../../firebase';
import { getFirestore } from 'redux-firestore'
import history from '../../../utils/history';

import './registerForm.css';

export class RegisterForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      privacyAccepted: false,
      confirmDirty: false,
      error: '',
      loading: false
    };
  }

  handleSubmit = (e) => {
    const { currentCity } = this.props;
    e.preventDefault();
    if (!this.state.privacyAccepted){
      this.setState({
        privacyAccepted: true
      });
      return;
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading:true});
        firebase.auth().createUserWithEmailAndPassword(values['email'], values['password']).then(data => {
          let userForFirestore = {
            firstName: values['firstName'],
            lastName: values['lastName'],
            email: values['email'],
            createdAt: getFirestore().FieldValue.serverTimestamp(),
            role: 'user'
          };
          getFirestore().collection('users').doc(data.user.uid).set(userForFirestore).then(()=>{
            this.setState({loading: false});
            // history.push(`/${currentCity}/job-offer`);
            console.log('111')
            this.props.history.push(`/${currentCity}/job-offer`);
          }).catch(error => {
            console.log('222',error);
            this.setState({loading: false, error: error.message});
          });
        }).catch(error => {
          console.log('333',error);
          this.setState({loading: false, error: error.message});
        });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { currentCity } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { privacyAccepted, error } = this.state;

    // firebase.auth().signOut();

    return (
      <Row style={{ paddingBottom: 100, textAlign: 'left !important' }}>
        <Col>
          <p className="jo-desc register-desc" style={{ marginBottom: 20, textAlign: 'center !important' }}>
            If you already have an account, you can login
            <Link to={`/${currentCity}/login`} className="jo-links"> here</Link>
          </p>
        </Col>
        <Col>
          <Form onSubmit={this.handleSubmit} className="custom-form-class">
            <Row className="text-align-left">
              <Form.Item label="E-mail" className="text-align-left">
                {getFieldDecorator('email', {
                  rules: [{
                    type: 'email', message: 'The input is not valid E-mail!',
                  }, {
                    required: true, message: 'Please input your E-mail!',
                  }],
                })(
                  <Input placeholder="[ Type your email here ]" />
                )}
              </Form.Item>
            </Row>
            <Row className="text-align-left">
              <Col md={11}>
                <Form.Item label="First Name" className="text-align-left">
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: 'Please input your first name!' }],
                  })(
                    <Input placeholder="[ Type your first name here ]" />
                  )}
                </Form.Item>
              </Col>
              <Col md={2}/>
              <Col md={11}>
                <Form.Item label="Last Name" className="text-align-left">
                  {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: 'Please input your last name!' }],
                  })(
                    <Input placeholder="[ Type your last name here]" />
                  )}
                </Form.Item>
              </Col>
            </Row>



            <Row className="text-align-left">
              <Col md={11}>
                <Form.Item label="Password" className="text-align-left">
                  {getFieldDecorator('password', {
                    rules: [{
                      required: true, message: 'Please input your password!'
                    }, {
                      validator: this.validateToNextPassword,
                    }],
                  })(
                    <Input placeholder="[ Type your password here ]" type="password"/>
                  )}
                </Form.Item>
              </Col>
              <Col md={2}/>
              <Col md={11}>
                <Form.Item label="Confirm Password" className="text-align-left">
                  {getFieldDecorator('confirm', {
                    rules: [{
                      required: true, message: 'Please confirm your password!'
                    }, {
                      validator: this.compareToFirstPassword,
                    }],
                  })(
                    <Input placeholder="[ Type your password here]" type="password" onBlur={this.handleConfirmBlur}/>
                  )}
                </Form.Item>
              </Col>
            </Row>


            <Row className='privacy-box text-align-center'>
              <h3 className='privacy-title'>
                <Icon type="lock" theme="filled" />
                Data Privacy Notice
              </h3>
              <p className='privacy-desc'>
                To submit the form you must agree on Venture Café storing and processing your data. The data will be displayed on VentureCafeTalentPortal.org and affiliated locations to support the functions and services of Talent Portal around the world.
              </p>
              <p className="privacy-desc-link">
                Read more our
                <a href='https://venturecafeglobal.org/vcgiwebsiteprivacy/' target="_blank" className="jo-links"> Privacy Policy here </a>
                for more information!
              </p>
              {error &&
              <Alert {...error} banner type="error" message={error} closable afterClose={() => {this.setState({error: ''})}} />
              }
              <Form.Item className='register-button-container'>
                <Button
                  type={privacyAccepted?'primary':'default'}
                  htmlType="submit"
                  loading={this.state.loading}
                  icon={privacyAccepted?'check':'exclamation'}>
                  {privacyAccepted?'SUBMIT':'CLICK TO AGREE'}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const WrappedNormalRegisterForm = Form.create()(RegisterForm);

const enhanced = compose(
  withRouter,
  connect(null, null),
);

export default enhanced(WrappedNormalRegisterForm);
