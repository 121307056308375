import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { message } from 'antd';

function getAddressParts(obj) {
  const address = [];

  obj.address_components.forEach( function(el) {
      address[el.types[0]] = el.long_name;
  });

  return address;
}

export default class LocationSearchInput extends React.Component {
  handleChange = city => {
    this.setState({ city });
    this.props.update({ city });
  };
  handleChange = address => {
    this.setState({ address });
    this.props.update({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const fullAddress = results[0].formatted_address;
        const addressParts = getAddressParts(results[0]);
        const city = addressParts.locality;
        const country = addressParts.country;
        // console.log('address', results, addressParts, city, country)
        if (city === undefined || country === undefined) {
          this.props.update({ address: null });
          this.props.update({ city: null });
          this.props.update({ country: null });
          message.error(`The place you've selected is not valid`);
          return getLatLng(null);
        }

        this.props.update({ address: fullAddress });
        this.props.update({ city });
        this.props.update({ country });

        return getLatLng(results[0]);
      })
      .then(_geoloc => {
        this.props.update({ _geoloc });
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          // types: ['(cities)']
          // types: ['address']
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: 'relative' }}>
            <input
              {...getInputProps({
                placeholder: 'Search address',
                className: 'ant-input',
              })}
            />
            {suggestions && suggestions.length > 0 &&
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { color: 'blue', cursor: 'pointer' }
                    : { color: 'black', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
