import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, Alert } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import firebase from '../../../firebase';
import history from '../../../utils/history';

import './loginForm.css';

export class LoginForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false,
      error: ''
    };
  }

  handleSubmit = (e) => {
    const { currentCity } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading:true});
        firebase.auth().signInWithEmailAndPassword(values['email'], values['password'])
          .then(result => {
            const currentUser = result.user;
            if (currentUser) {
              this.setState({loading:false});
              this.props.history.push(`/${currentCity}/job-offer`);
            }
          })
          .catch(error => {
            this.setState({ error: error.message, loading: false })
          });
      }
    });
  };

  render() {
    const { currentCity } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, error } = this.state;

    return (
      <Row style={{ textAlign: 'left !important' }}>
        <Col>
          <p className="jo-desc register-desc" style={{ marginBottom: 20, textAlign: 'center !important' }}>
            If you don't have an account, you can register
            <Link to={`/${currentCity}/register`} className="jo-links"> here</Link>
          </p>
        </Col>
        <Col>
          <Form onSubmit={this.handleSubmit} className="custom-form-class">
            <Row className="text-align-left">
              <Col md={11} className="text-align-left">
                <Form.Item label="E-mail" className="text-align-left">
                  {getFieldDecorator('email', {
                    rules: [{
                      type: 'email', message: 'The input is not valid E-mail!',
                    }, {
                      required: true, message: 'Please input your E-mail!',
                    }],
                  })(
                    <Input placeholder="[ Type your email here ]" />
                  )}
                </Form.Item>
              </Col>
              <Col md={2}/>
              <Col md={11} className="text-align-left">
                <Form.Item label="Password" className="text-align-left">
                  {getFieldDecorator('password', {
                    rules: [{
                      required: true, message: 'Please input your password!'
                    }],
                  })(
                    <Input placeholder="[ Type your password here ]" type="password"/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {error &&
              <Alert {...error} banner type="error" message={error} closable afterClose={() => {this.setState({error: ''})}} />
              }
              <Form.Item className='register-button-container text-align-center'>
                <Button
                  type='primary'
                  htmlType="submit"
                  className="text-align-center"
                  loading={loading}>
                  Log In
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

// const WrappedNormalLoginForm = Form.create()(LoginForm);

const enhanced = compose(
  withRouter,
  Form.create()
)

export default enhanced(LoginForm);
