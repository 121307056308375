import React from 'react';
import { Form, Icon } from 'antd';

export const render = Component => ({
  input, meta, children, hasFeedback, label, checked, ...rest
}) => {
  const checkedValue = {};
  if (checked != null) {
    checkedValue.checked = checked(input.value);
  }

  const hasError = meta.touched && meta.invalid;
  return (
    <Form.Item
      label={label}
      colon={label && label === ' ' ? '' : ':'}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      extra={rest.extra}
    >
      <Component {...input} {...checkedValue} {...rest} children={children} />
    </Form.Item>
  );
};

export const renderConnected = Component => ({
  names, children, hasFeedback, label, ...rest
}) => {
  // main input component
  const { input } = rest[names[0]];
  const hasError = names.filter((name) => rest[name].meta.touched && rest[name].meta.invalid).length > 0;
  const error = names.map((name) => rest[name].meta.error).filter(err => err != null && err.length > 0).join(',');

  const updateOthers = (values) => {
    const [key, value] = Object.entries(values)[0];
    if (names.includes(key)) {
      rest[key].input.onChange(value);
    }
  }

  return (
    <Form.Item
      label={label}
      colon={label && label === ' ' ? '' : ':'}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && error}
      extra={rest.extra}
      required={rest.required}
    >
      <Component {...input} {...rest} update={updateOthers} children={children} />
    </Form.Item>
  );
};

export const renderUpload = Component => ({
  input, meta, children, hasFeedback, label, ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  const fileList = [];
  if (input.value != null && input.value !== '') {
    fileList.push({
      uid: '-1',
      name: rest.filename || '',
      status: 'done',
      url: input.value,
    });
  }

  const formItemLayout = rest.formItemLayout || {}
  return (
    <Form.Item
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      {...formItemLayout}
    >
      <Component
        {...rest}
        fileList={fileList}
        onRemove={() => { input.onChange(null)}}
        onSuccess={(file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const dataURL = e.target.result;
            if (rest.size && rest.size.width === 300) {
              resizedataURL(dataURL, rest.size.width, rest.size.height)
                .then(data => input.onChange(data));
            } else {
              input.onChange(dataURL);
            }
          };

          reader.readAsDataURL(file);

        }}
        customRequest={({
          data, file, filename, onSuccess,
        }) => {
          onSuccess(file, filename);
        }}
      >
        {children ? children : fileList.length >= 1 ? null :
          (<div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
          </div>)
        }
      </Component>
    </Form.Item>
  )
};

function resizedataURL(datas, iWidth, iHeight) {
  // console.log({datas, iWidth, iHeight})
  return new Promise((resolve) => {
    const img = document.createElement('img');

    img.onload = function() {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const oWidth = img.width;
      const oHeight = img.height;
      let nWidth = 300;
      let nHeight = 300;
      // if (iWidth > nWidth) {
      //   nWidth = iWidth;
      //   nHeight = iHeight;
      // }
      let x = 0;
      let y = 0;

      canvas.width = nWidth;
      canvas.height = nHeight;

      if (oWidth > oHeight) {
        nWidth = oWidth * (nHeight / oHeight);
        x = (nHeight - nWidth) / 2;
      } else {
        nHeight = oHeight * (nWidth / oWidth);
        y = (nWidth - nHeight) / 2;
      }
      ctx.drawImage(this, x, y, nWidth, nHeight);

      const dataURI = canvas.toDataURL();
      resolve(dataURI);
    };
    img.src = datas;
  })
};
