import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Divider,
  Icon,
  Select,
  AutoComplete,
  DatePicker,
  Upload,
  Checkbox,
  message, notification,
  Modal,
  Avatar,
} from 'antd';
import BraftEditor from 'braft-editor';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import firebase from '../../../firebase';
import history from '../../../utils/history';

import './jobOfferAuthenticated.css';
import 'braft-editor/dist/index.css';
import { helper } from '../../../algolia';

import JobForm from './JobForm';
import { pathOr } from 'ramda';
import { submit } from 'redux-form';

const Option = Select.Option;

const searchOptions = {
  types: ['(cities)']
}

export class JobOfferAuthenticated extends Component {
  constructor(props){
    super(props);
    this.state = {
      privacyAccepted: false,
      address: '',
      officeAddress: '',
      latLng: [],
      imageUrl: null,
      company_logo: null,
      loadingSubmit: false,
      country: '',
      city: '',
      industries: [],
      keywords: [],
      employments: [],
      openSuccessModal: false,
      //
      jobs: [],
      editableRecord: null,
      searchText: '',
      submitting: false,
    };
  }

  // componentDidMount() {
  //   helper.on('result', (result) => {
  //     const industriesData = result.getFacetValues('industry');
  //     const keywordsData = result.getFacetValues('keywords');
  //     const employmentsData = result.getFacetValues('employment');

  //     const industries = industriesData.map((item, idx) => {
  //       return <Option key={idx} value={item.name}>{item.name}</Option>
  //     });

  //     const keywords = keywordsData.map((item, idx) => {
  //       return <Option key={idx} value={item.name}>{item.name}</Option>
  //     });

  //     const employments = employmentsData.map((item, idx) => {
  //       return <Option key={idx} value={item.name}>{item.name}</Option>
  //     });

  //     this.setState({ industries, keywords, employments });
  //   });

  //   helper.search();
  // }

  // componentDidMount() {
  //   helper.on('result', (result) => {
  //     const industries = result.getFacetValues('industry');
  //     const keywords = result.getFacetValues('keywords');
  //     const employments = result.getFacetValues('employment');

  //     this.setState({ industries, keywords, employments });
  //   });

  //   helper.search();
  // }

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   const { company_logo, city, country, officeAddress, latLng } = this.state;

  //   if (!this.state.privacyAccepted){
  //     this.setState({
  //       privacyAccepted: true
  //     });
  //     return;
  //   }

  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.setState({loadingSubmit: true});

  //       Object.entries(values).forEach(item => {
  //         if (item[1]===undefined || item[1]===null)
  //           values[item[0]] = '';
  //         if (typeof item[1] === 'boolean')
  //           values[item[0]] = Number(item[1]);
  //       });

  //       console.log('show_salary: ', values.show_salary);
  //       console.log('feature_request: ', values.feature_request);

  //       values.description =  values.description.toHTML();
  //       // values.address = officeAddress;
  //       values.city = city;
  //       values.country = country;
  //       values.status = 'pending';
  //       values._geoloc = latLng;
  //       values.startDate = values.startDate.toDate();
  //       values.keywords = [values.keywords];
  //       getFirestore().add({ collection: 'joblistings' }, { ...values }).then(snapshot => {
  //         if (company_logo){
  //           this.uploadLogo(company_logo, snapshot.id);
  //         } else {
  //           this.submitFinish();
  //         }
  //       }).catch(error => {
  //         console.log('error: ', error);
  //         message.error(error.message);
  //         this.setState({loadingSubmit: false});
  //       });
  //     }
  //   });
  // };

  successCreateJob() {
    Modal.success({
      title: 'Job Post have successfully created',
      content: `Your application will be under review.\nYou will be redirected to the Talent Portal page.`,
      maskClosable: false,
      keyboard: false,
      className: 'modalSuccess',
      centered: true,
      onOk: () => this.redirect(),
    });
  }

  redirect = () => {
    const { currentCity } = this.props;
    // window.location.href = "/home/";
    this.props.history.push(`/${currentCity}/home`);
  }

  // submitFinish = () => {
  //   this.setState({loadingSubmit: false});
  //   message.success('Successful!');
  //   this.successCreateJob();
  //   // setTimeout(()=>{
  //   //   window.location.href = "/home";
  //   // }, 1500);
  // };

  // normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  // handleAddressChange = address => {
  //   this.setState({ address });
  // };

  // handleAddressSelect = address => {
  //   geocodeByAddress(address)
  //     .then(results => {
  //       this.setState({ address: results[0].formatted_address });

  //       let i = 0;
  //       for( let key in results[0].address_components) {
  //         if(results[0].address_components[key].types[0] === "locality") {
  //           i++
  //           this.setState({ city: results[0].address_components[key].long_name });
  //         } else if(results[0].address_components[key].types[0] === "administrative_area_level_1") {
  //           if(i === 0) {
  //             this.setState({ city: results[0].address_components[key].long_name });
  //           }
  //         } else if(results[0].address_components[key].types[0] === "country") {
  //           this.setState({ country: results[0].address_components[1].long_name });
  //         }
  //       }

  //       getLatLng(results[0])
  //         .then(latLng => this.setState({ latLng: latLng }))
  //         .catch(error => console.error('Error', error));
  //     });
  // };

  // onUpload = (fileList) => {
  //   this.setState({ company_logo: fileList.file });
  // };

  // beforeUpload = (file) => {
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }
  //   return isLt2M;
  // };

  // handleLogoChange = (info) => {
  //   this.getBase64(info.file.originFileObj, imageUrl => this.setState({
  //     imageUrl: imageUrl
  //   }));
  // };

  // getBase64 = (img, callback) => {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // };

  // uploadLogo = (company_logo, key) => {
  //   firebase.uploadFile(`/company_images/${key}`, company_logo)
  //     .then((snapshot) => {
  //       const { task } = snapshot.uploadTaskSnaphot;
  //       firebase.storage().ref().child(`${task.location_.path}`).getDownloadURL().then((url) => {
  //         getFirestore().update({ collection: 'joblistings', doc: key }, {logo: url}).then(() => {
  //           this.submitFinish();
  //         });
  //       }).catch((error) => {
  //         console.log('error: ', error);
  //         message.error(error.message);
  //         this.setState({loadingSubmit: false});
  //       });
  //     })
  //     .catch(error => {
  //       console.log('error: ', error);
  //       message.error(error.message);
  //       this.setState({loadingSubmit: false});
  //     });
  // };

  //////////////////////////////////////////////////////////////////////////////////////////////////

  uploadFile = (file, prop, key) => {
    const { storage } = this.props.firebase;
    return new Promise((resolve, reject) => {
      if (file.indexOf('data') !== 0) {
        return resolve({ [prop]: file });
      }
      const ref = storage().ref(`/company_images/${key}/${prop}`)
      const uploadTask = ref.putString(file, 'data_url');
      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case storage.TaskState.PAUSED:
            console.log('Upload is paused');
            break;
          case storage.TaskState.RUNNING:
            console.log('Upload is running');
            break;
        }
      }, (error) => {
        reject(error);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve({ [prop]: downloadURL })
        });
      });
    })
  }

  saveFormData = () => {
    // this.setState({ submitting: true });
    this.props.dispatch(submit('job'));
  }

  // submitForm = (values) => {
  //   console.log({values});
  // }

  submitForm = (values) => {
    // console.log({values})
    this.setState({ submitting: true });
    let { id, logo, ...data } = values;
    const ref = this.props.firestore.collection('joblistings');
    if(data.featured) {
      data.featured = 1;
    } else {
      data.featured = 0;
    }
    if (data.vc_city) {
      data.vc_city = this.props.vc_city || data.vc_city;
    } else {
      data.vc_city = this.props.vc_city || '';
    }
    let update;
    if (id == null) {
      data.status = 'pending';
      update = ref.add(data);
    } else {
      update = ref.doc(id).update(data)
    }
    update
      .then((doc) => {
        id = pathOr(id, ['id'], doc);
        return this.uploadFile(logo, 'logo', id);
      })
      .then((uploadResult) => {
        return ref.doc(id).update(uploadResult)
      })
      .then(() => {
        this.setState({ submitting: false });
        // this.closeModal();
        // notification.success({
        //   message: `Success`,
        //   description: `Job created successfully!`,
        // });
        this.successCreateJob();
      });
  }

  render() {
    const { currentCity, industries, functions, employment } = this.props;
    // const { getFieldDecorator } = this.props.form;
    // const { privacyAccepted, imageUrl } = this.state;
    const {
      // industries,
      // keywords,
      // employments,
      editableRecord,
      showModal,
      submitting,
      privacyAccepted
    } = this.state;

    return (
      <Row style={{ paddingBottom: 100 }}>
        <Row>
          <Col>
            <p className="jo-desc">
              Welcome to add a job offer to the Venture Café’s Talent Portal. This information will be published after
              approval by the
              website administrator.
            </p>
          </Col>
        </Row>
        <Row className="text-align-left custom-form-class" style={{ textAlign: 'left !important' }}>
          <JobForm submit={this.submitForm} record={{}} industries={industries} keywords={functions} employment={employment} />
        </Row>
        <Row className='privacy-box'>
          <h3 className='privacy-title'>
            <Icon type="lock" theme="filled" />
            Data Privacy Notice
          </h3>
          <p className='privacy-desc'>
            To submit the form you must agree on Venture Café storing and processing your data. The data will be displayed on VentureCafeTalentPortal.org and affiliated locations to support the functions and services of Talent Portal around the world.
          </p>
          <p className="privacy-desc-link">
            Read more our
            <a href='https://venturecafeglobal.org/vcgiwebsiteprivacy/' target="_blank" className="jo-links"> Privacy Policy here </a>
            for more information!
          </p>
          <Form.Item className='register-button-container'>
            <Button
              key="submit"
              type={privacyAccepted?'primary':'default'}
              // htmlType="submit"
              // loading={submitting}
              // disabled={submitting}
              icon={privacyAccepted?'check':'exclamation'}
              onClick={privacyAccepted ? this.saveFormData : () => this.setState({ privacyAccepted: !privacyAccepted })}
            >
              {privacyAccepted?'SUBMIT':'CLICK TO AGREE'}
            </Button>
          </Form.Item>
        </Row>
      </Row>
    );
  }
}

// const WrappedNormalJobOfferAuthenticated = Form.create()(JobOfferAuthenticated);

// export default connect(null, null)(WrappedNormalJobOfferAuthenticated);

const enhanced = compose(
  withRouter,
  // firestoreConnect(() => {
  //   return [
  //     {
  //       collection: 'joblistings',
  //     },
  //   ];
  // }),
  firestoreConnect(['/joblistings', '/employment', '/industries', '/functions', '/cities']),
  connect(state => {
    const current_user_id = state.firebase.auth.uid;
    const user_profile = state.firebase.profile;
    const { employment, industries, functions, cities } = state.firestore.data;
    let currentCity = window.location.pathname.split('/')[1];

    const vc_city = Object.entries(pathOr([], ['data', 'cities'], state.firestore))
      .find((item) => {
        if (item[1].slug && item[1].slug.includes(currentCity)) {
          return true;
        }
      });

    const jobs = Object.values(pathOr([], ['data', 'joblistings'], state.firestore))
      .map(job => ({
        ...job[1],
        objectID: job[0],
        key: job[0], // <-- for table primary key
      }))
      .sort((a, b) => {
        if (a.publicationDate == null) {
          return -1;
        } if (b.publicationDate == null) {
          return 1;
        }
        return b.publicationDate.seconds - a.publicationDate.seconds;
      });

    return {
      current_user_id,
      user_profile,
      jobs,
      employment: employment ? Object.values(employment) : [],
      industries: industries ? Object.values(industries) : [],
      functions: functions ? Object.values(functions) : [],
      vc_city: (vc_city && vc_city[1] && vc_city[1].description) ? vc_city[1].description : null,
    };
  }),
);

export default enhanced(JobOfferAuthenticated);
