import algoliasearch from 'algoliasearch';
import algoliasearchHelper from 'algoliasearch-helper';

const JOBINDEX = 'joblistings';
const STARTDATEINDEX = 'Start Date';
const PUBLICATIONDATEINDEX = 'Publish Date';

// const JOBINDEX = 'staging_joblistings';
// const STARTDATEINDEX = 'staging_Start Date';
// const PUBLICATIONDATEINDEX = 'staging_Publish Date';

const client = algoliasearch('UO96V5GBQT', '459c98eb71ec7781e0928d27a375eece');
const index = client.initIndex(JOBINDEX);
const startindex = client.initIndex(STARTDATEINDEX);
const publishindex = client.initIndex(PUBLICATIONDATEINDEX);
const helper = algoliasearchHelper(client, JOBINDEX, {
  facets: ['keywords', 'industry', 'employment', 'vc_city'],
});

export {
  client, helper, index, startindex, publishindex,
};
