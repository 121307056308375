import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import './jobOfferUnauthenticated.css';
import { Link } from 'react-router-dom';

export class JobOfferUnauthenticated extends Component {

  componentDidMount() {
  }

  render() {
    const { currentCity } = this.props;
    return (
      <Row style={{ paddingBottom: 100 }}>
        <Row>
          <Col>
            <p className="jo-desc">
              Welcome to add a job offer to the Venture Café’s Talent Portal. This information will be published after
              approval by the
              website administrator. Note that you have to register in order to submit your job offer!
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="jo-unregister-desc">
              In order to fill this form, please
              <Link to={`/${currentCity}/register`} className="jo-links"> register</Link>
              , or if you already have an account, please log in
              <Link to={`/${currentCity}/login`} className="jo-links"> here</Link>
              .
            </p>
          </Col>
        </Row>
      </Row>
    );
  }
}

export default connect(null, null)(JobOfferUnauthenticated);
