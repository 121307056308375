import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Icon, Modal, Button, message } from 'antd';
import { FiChevronDown, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import AliceCarousel from 'react-alice-carousel';
import Truncate from 'react-truncate';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import 'react-alice-carousel/lib/alice-carousel.css';

import './featuredList.css';
import JobOverview from './jobOverview';

let windowWidth = window.innerWidth;
// console.log({windowWidth})

// eslint-disable-next-line react/prefer-stateless-function
export class FeaturedList extends Component {
  constructor() {
    super();
    this.state = {
      currentCircle: 0,
      openJobOverview: false,
      selectedJob: [],
      currentIndex: 0,
      itemsInSlide: 1,
      responsive: {
        0: {
          items: 1
        },
        1024: {
          items: 3
        }
      },
      // galleryItems: this.galleryItems(),
      galleryItems: [],
      jobURLLink: '',
    };
    this.featuredRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    windowWidth = window.innerWidth;
    if (prevProps.data !== this.props.data && this.props.data !== undefined && this.props.data.length > 0) {
      this.setState({ galleryItems: this.galleryItems() })
    }
  }

  galleryItems() {
    // const { data } = this.props;
    // console.log(this.props)
    if (!this.props || !this.props.data) {
      return (
        Array(7).fill().map((item, i) => <h2 className="item">{i + 1}</h2>)
      )
    }
    const { data, dataLength } = this.props;
        // console.log('222', this.props.data)
    return (
      // Array(7).fill().map((item, i) => <h2 className="item">{i + 1}</h2>)
      data.map((data, index) => {
        return this.featuredCard(data, index);
      })
    )
  }

  onSlideChanged = (e) => {
    console.log({e})
    this.setState({ currentIndex: e.item });
  }

  slideNext = () => {
    this.setState({ currentIndex: this.state.currentIndex + 3 });
  }

  slidePrev = () => {
    this.setState({ currentIndex: this.state.currentIndex - 3 });
  }

  gallery = () => {
    const { currentIndex, galleryItems, responsive } = this.state
    // console.log("test", currentIndex)
    // const handleOnDragStart = e => e.preventDefault()
    return (
      <AliceCarousel
        style={{ width: '100%', maxWidth: '994px' }}
        // dotsDisabled={true}
        buttonsDisabled={true}
        items={galleryItems}
        responsive={responsive}
        startIndex={currentIndex}
        slideToIndex={currentIndex}
        onSlideChanged={this.onSlideChanged}
        mouseDragEnabled={windowWidth < 1024 ? true : false}
        // swipeDisabled={true}
        stagePadding={windowWidth < 1024 && {
          paddingLeft: 30,     // in pixels
          paddingRight: 30
        }}
      />
    )
  }

  thumbItem = (item, i) => <span onClick={() => this.slideTo(i)}>* </span>

  // featuredIndex() {
  //   const { data, dataLength } = this.props;
  //   const { currentCircle, currentIndex } = this.state;
  //   if (dataLength) {
  //     // let totalIndex = Math.ceil(dataLength/3);
  //     let totalIndex = Math.floor(dataLength/3);
  //     let arr = new Array(totalIndex);
  //     console.log({dataLength});
  //     console.log({totalIndex});
  //     console.log({currentCircle});
  //     // console.log(dataLength, totalIndex, currentCircle)
  //     return (
  //       Array(totalIndex).fill().map((data,index) => {
  //         // console.log('test', index, currentIndex);
  //         console.log({index});
  //         console.log({currentIndex});
  //         return (
  //           <div className={currentCircle === index ? 'pageCircleCurrent' : 'pageCircle'} />
  //       )})
  //     )
  //   }
  // }

  // openJobModal = (selectedJob) => {
  //   this.setState({ openJobOverview: true, selectedJob: selectedJob })
  //   // window.location.href = `/jobOverview/${selectedJob.objectID}`;
  // }

  openJobModal = (selectedJob) => {
    // console.log({props});
    this.setState({ openJobOverview: true, selectedJob: selectedJob })
    let url = (window.location != window.parent.location)
          ? document.referrer + `?jobid=${selectedJob.objectID}`
          : document.location.href.substring(0, document.location.href.lastIndexOf('/')) + `/jobOverview/?jobid=${selectedJob.objectID}`;
    // console.log({url});
    this.setState({ jobURLLink: url });
    // window.location.href = `/jobOverview/${props.selectedJob.objectID}`;
  }

  featuredCard(data, index) {
    const { title, logo, employment, organization, featured } = data;
    // console.log(title, logo, employment, organization);
    return (
      <div
        className="featuredCardShadow"
      >
        <button
          key={index}
          className="featuredCardBody"
          style={{
            // marginLeft: index === 0 ? 0 : 31
          }}
          type="button"
          onClick={() => this.openJobModal(data)}
        >
          <div style={{ width: '171px', height: '70px', marginRight: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <div style={{ fontSize: '14px',  fontWeight: '300', lineHeight: '17px' }}>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {title}
                </Truncate>
              </div>
              <div style={{ fontSize: '13px',  fontWeight: '200', lineHeight: '16px' }}>
                {data.organization ? data.organization : data.company_name}
              </div>
            </div>
            <div className="colorPrimary" style={{ fontSize: '12px',  fontWeight: '200', lineHeight: '14px' }}>
              {employment}
            </div>
          </div>
          <div>
            <img src={logo} alt={title} style={{ height: '70px', width: '70px', objectFit: 'contain' }}/>
          </div>
        </button>
      </div>
    );
  };

  filterCompany = (company_name) => {
    this.setState({ openJobOverview: false }, this.onChangeCompany(company_name));
  }

  onChangeCompany = (company_name) => {
    const { onChange } = this.props;

    onChange({ query: company_name }, true);
  }

  // handleOk = (e) => {
  //   // console.log(e);
  //   this.setState({
  //     openJobOverview: false,
  //   });
  // }
  handleOk = (e) => {
    // console.log(e);
    const { selectedJob } = this.state;
    // if (selectedJob.website_link) {
    if (selectedJob.website) {
      if (!selectedJob.website.includes('http')) {
        window.open(`http://${selectedJob.website}`, '_blank');
      } else {
        window.open(selectedJob.website, '_blank');
      }
    } else if (selectedJob.email) {
      let email = 'mailto:' + selectedJob.email;
      window.open(email, '_blank');
    }
    this.setState({
      openJobOverview: false,
    });
  }

  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      openJobOverview: false,
    });
  }

  // shareLink = () => {
  //   const { selectedJob } = this.state;
  //   let url = window.location.hostname + '/jobOverview/' + selectedJob.objectID;
  //   // console.log({url});
  //   this.setState({ jobURLLink: url });
  //   message.success('Job link have been successfully copied');
  //   // return url;
  // }

  shareLink = () => {
    const { selectedJob } = this.state;
    // let url = window.location.hostname + '/jobOverview/' + selectedJob.objectID;
    let url = (window.location != window.parent.location)
          ? document.referrer + `/?jobid=${selectedJob.objectID}`
          : document.location.href.substring(0, document.location.href.lastIndexOf('/')) + `/jobOverview/?jobid=${selectedJob.objectID}`;
    // copyUrl = url.substring(0, url.lastIndexOf('/')) + '/?' + ;
    // console.log({url});
    this.setState({ jobURLLink: url });
    message.success('Job link have been successfully copied');
    // return url;
  }

  render() {
    const { currentCircle, selectedJob, openJobOverview } = this.state;
    const { data, dataLength } = this.props;

    // console.log({data})

    return (
      <Row
        className="featuredContainer"
        // gutter={{ sm: 13.09, md: 30 }}
        // type="flex"
        // justify={{ sm: 'start', md: "center" }}
      >
        <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }} className="featuredHeaderContainer">
          <div className="featuredTitle">
            Featured Job Postings
          </div>
          <div className="pageCircleContainer">
          </div>
        </Col>
        <Col
          xs={{ offset: 0, span: 24 }}
          md={{ offset: 2, span: 20 }}
          style={{
            // height: '120px',
            maxWidth: 994,
            position: 'relative',
            zIndex: 1,

            // marginTop: '20px',
            // marginBottom: '-20px',
          }}
        >
          {this.gallery()}
        </Col>
        {/*
        <div style={{ height: '120px', overflowY: 'hidden', position: 'relative', zIndex: 1 }}>
          <div ref={this.featuredRef} className="featuredCardContainer" style={{ marginTop: '20px', overflowX: 'scroll', height: '120px', marginBottom: '-20px' }}>
            {data.map((data, index) => {
              return this.featuredCard(data, index);
            })}

          </div>
        </div>
        */}
        <Col
          xs={0}
          sm={0}
          md={24}
          className="featuredArrowContainer"
        >
          <button
            type="button"
            style={{
              background: 'transparent',
              border: 'none',
              padding: '0px',
              outline: 'none',
              cursor: 'pointer'
            }}
            onClick={() => this.slidePrev()}
            // onClick={() => {
            //   this.featuredRef.current.scrollLeft -= (317*3);
            //   if (currentCircle !== 1) {
            //     this.setState({ currentCircle: currentCircle - 1 });
            //   }
            //   console.log('scroll');
            // }}
          >
            <FiChevronLeft style={{ height: '70px', width: '40px' }} />
          </button>
          <button
            type="button"
            style={{
              background: 'transparent',
              border: 'none',
              padding: '0px',
              outline: 'none',
              cursor: 'pointer'
            }}
            onClick={() => this.slideNext()}
            // onClick={() => {
            //   this.featuredRef.current.scrollLeft += (317*3);
            //   if (currentCircle !== 3) {
            //     this.setState({ currentCircle: currentCircle + 1 });
            //   }
            //   console.log('scroll');
            // }}
          >
            <FiChevronRight style={{ height: '70px', width: '40px' }} />
          </button>
        </Col>
        {openJobOverview && (
          <Modal
            visible={openJobOverview}
            width={900}
            className={selectedJob.featured === 1 ? "jobOverviewModalFeatured" : "jobOverviewModal"}
            bodyStyle={{
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: '#434343',
              borderRadius: '5px',
              paddingBottom: '55px',
              marginBottom: '-55px',
            }}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <CopyToClipboard text={this.state.jobURLLink}>
                <Button onClick={this.shareLink} style={{ border: 'none'  }} key="submit" type="primary">
                  Copy Link
                </Button>
              </CopyToClipboard>,
              <Button style={{ border: 'none'  }} key="submit" type="primary" onClick={this.handleOk}>
                Apply
              </Button>
            ]}
          >
            <JobOverview data={selectedJob} filterCompany={this.filterCompany} />
          </Modal>
        )}
      </Row>
    );
  }
}

export default connect(null, null)(FeaturedList);
