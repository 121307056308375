import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Menu, Dropdown, Icon, Modal, Input } from 'antd';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import './addJobButton.css';
// import { helper } from '../../../algolia';

let windowWidth = window.innerWidth;
// console.log({windowWidth})

// const employmentType = [
//   'Freelance',
//   'Full-Time',
//   'Part-Time',
//   'Internship',
//   'Traineeship',
//   'Other',
// ];

// eslint-disable-next-line react/prefer-stateless-function
export class AddJobButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // authenticated: false,
      // openModal: false,
      // title: '',
      // industryDropDown: false,
      // industries: [],
      // industrySelected: [],
      // employmentDropDown: false,
      // employments: [],
      // employmentSelected: null,
      // functionDropDown: false,
      // functions: [],
      // functionSelected: null,
    };
  }

  // componentDidMount() {
  //   helper.on('result', (result) => {
  //     const industries = result.getFacetValues('industry');
  //     // console.log(industries);

  //     this.setState({ industries });

  //     const functions = result.getFacetValues('keywords');
  //     // console.log(functions);

  //     this.setState({ functions });

  //     const employments = result.getFacetValues('employment');
  //     // console.log(functions);

  //     this.setState({ employments });
  //   });

  //   helper.search();
  // }

  // openModal() {
  //   // const { authenticated } = this.state;
  //   // if (authenticated) {
  //   //   this.setState({ openAddJobModal: true })
  //   // } else {
  //   //   this.setState({ openRegisterModal: true })
  //   // }

  // }

  // handleOkAddJob = (e) => {
  //   console.log(e);
  //   this.setState({
  //     openAddJobModal: false,
  //   });
  // }

  // handleCancelAddJob = (e) => {
  //   console.log(e);
  //   this.setState({
  //     openAddJobModal: false,
  //     authenticated: false,
  //   });
  // }

  // handleOkRegister = (e) => {
  //   console.log(e);
  //   this.setState({
  //     openRegisterModal: false,
  //     authenticated: true,
  //   });
  // }

  // handleCancelRegister = (e) => {
  //   console.log(e);
  //   this.setState({
  //     openRegisterModal: false,
  //   });
  // }

  // functionMenu() {
  //   const { functions } = this.state;
  //   return (
  //     <Menu>
  //       {functions.map((item,index) => (
  //         <Menu.Item key={index}>
  //           <a href={null}>{item.name}</a>
  //         </Menu.Item>
  //       ))}
  //     </Menu>
  //   );
  // }

  // employmentMenu() {
  //   const { employments } = this.state;
  //   return (
  //     <Menu>
  //       {employments.map((item,index) => (
  //         <Menu.Item key={index}>
  //           <a href={null}>{item.name}</a>
  //         </Menu.Item>
  //       ))}
  //     </Menu>
  //   );
  // }

  // industryMenu() {
  //   const { industries } = this.state;
  //   return (
  //     <Menu>
  //       {industries.map((item,index) => (
  //         <Menu.Item key={index}>
  //           <a href={null}>{item.name}</a>
  //         </Menu.Item>
  //       ))}
  //     </Menu>
  //   );
  // }

  render() {
    const { currentCity } = this.props;
    // const useless = null;
    // const moreUseless = null;

    return (
      <div
        className="addJobButton-container"
      >
        <Link to={`/${currentCity}/job-offer`}>
          <div className="btnAddJobShadow">
            <button type="button" className="btnAddJob">
              <FiPlus className="btnAddJobIcon" />
              <div style={{ marginTop: '2px', marginLeft: '2px' }}>{windowWidth < 992 ? 'Add Job' : 'Add Job Posting'}</div>
            </button>
          </div>
        </Link>
      </div>
    );
  }
}

export default connect(null, null)(AddJobButton);
