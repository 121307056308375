import React from 'react'
import { Input, Checkbox, DatePicker, AutoComplete, Select, Upload } from 'antd'
import BraftEditor from 'braft-editor';
import moment from 'moment'
import { render, renderUpload, renderConnected } from '../../../utils/form'
import * as validate from '../validate'
import LocationSearchInput from '../../../components/common-ui/LocationSearchInput';
import './form.css';

const InputField = render(Input);
const OptionField = render(Checkbox);
const CalendarField = render(DatePicker);
const AutoCompleteField = render(AutoComplete);
const DropdownField = render(Select);
const TextAreaField = render(Input.TextArea);
const HTMLEditorField = render(BraftEditor);
const UploadField = renderUpload(Upload);
const GooglePlacesField = renderConnected(LocationSearchInput);


function getOptions(options) {
  return options && options.map(option => (
    <Select.Option key={option.key} value={option.key}>{option.text}</Select.Option>
  ));
}

// function useEmail(a) {
//   return a;
// }



export default function fieldConfig(options) {
  return [
    // ROW 1
    {
      layout: { sm: 24, md: 24 },
      name: 'title',
      label: 'Job title',
      placeholder: 'Type the title of your job offering here',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      component: InputField,
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
    }, {
      layout: { sm: 24, md: 12 },
      name: 'keywords',
      label: 'Job function',
      className: 'form-item-select text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      placeholder: 'Function',
      getPopupContainer: trigger => trigger.parentNode,
      // component: AutoCompleteField,
      component: DropdownField,
      filterOption: (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0,
      // dataSource: options['keywords'] ? options['keywords'].map(opt => opt.name) : [],
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
      children: getOptions(options['keywords'] ? options['keywords'].map(opt => ({ key: opt.name, text: opt.name})) : []),
    }, {
      layout: { sm: 24, md: 12 },
      name: 'employment',
      label: 'Employment type',
      className: 'form-item-select text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      placeholder: 'Employment',
      getPopupContainer: trigger => trigger.parentNode,
      // component: AutoCompleteField,
      component: DropdownField,
      filterOption: (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0,
      // dataSource: options['employment'] ? options['employment'].map(opt => opt.name) : [],
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
      children: getOptions(options['employment'] ? options['employment'].map(opt => ({ key: opt.name, text: opt.name})) : []),
    },
    // ROW 2
    {
      layout: { sm: 24, md: 24 },
      name: 'industry',
      label: 'Industries',
      className: 'form-item-select text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      placeholder: 'Industries',
      getPopupContainer: trigger => trigger.parentNode,
      component: DropdownField,
      validate: [validate.required, validate.max3Selected],
      parentClassName: 'required-label-asterisk',
      format: (value) => {
        if (value != null) {
          return Array.isArray(value) ? value : [value];
        }
        return [];
      },
      mode: 'multiple',
      maxTagCount: 2,
      filterOption: (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0,
      children: getOptions(options['industries'] ? options['industries'].map(opt => ({ key: opt.name, text: opt.name})) : [{ key: -1, text: 'loading...'}]),
    }, {
      layout: { sm: 24, md: 24 },
      name: 'startDate',
      label: 'Startdate of job',
      placeholder: 'Date',
      style: { width: '100%', textAlign: 'left !important' },
      className: 'text-align-left border-radius-4',
      format: (value) => {
        let date;
        if (value && value.seconds) {
          date = value.seconds * 1000;
        } else if (value) {
          date = value;
        } else {
          date = Date.now();
        }
        return moment(date);
        // return moment((value && value.seconds * 1000) || Date.now());
      },
      normalize: (value) => value && value.valueOf() || '',
      getCalendarContainer: trigger => trigger.parentNode,
      // getPopupContainer: trigger => trigger.parentNode,
      component: CalendarField,
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
    }, {
    //   layout: { sm: 24, md: 12 },
    //   name: 'salary_tier',
    //   label: 'Salary Tier',
    //   className: 'form-item-select text-align-left border-radius-4',
    //   style: { textAlign: 'left !important' },
    //   placeholder: 'Select',
    //   getPopupContainer: trigger => trigger.parentNode,
    //   component: DropdownField,
    //   filterOption: (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0,
    //   children: getOptions([
    //     {key: 1, text: 'less than 2000' },
    //     {key: 2, text: '2000 < ... < 3000' },
    //     {key: 3, text: '3000 < ... < 4000' },
    //     {key: 4, text: '4000 < ... < 5000' },
    //     {key: 5, text: '5000 < ... < 6000' },
    //     {key: 6, text: 'more than 6000' },
    //   ]),
    //   validate: [validate.required],
    //   parentClassName: 'required-label-asterisk',
    // }, {
      layout: { sm: 24, md: 12 },
      name: 'salary_tier',
      label: 'Annual Salary',
      placeholder: 'Annual salary in local currency',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      component: InputField,
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
    }, {
      layout: { sm: 24, md: 12 },
      name: 'show_salary',
      label: ' ',
      component: OptionField,
      className: 'text-align-left',
      style: { textAlign: 'left !important' },
      children: "Show on page",
      checked: (value) => value > 0
    },
    // ROW 3
    {
      layout: { span: 24 },
      name: 'short_description',
      label: 'Short job description',
      placeholder: 'Short description',
      className: 'text-align-left border-radius-4',
      validate: [validate.required, validate.maxLength120],
      parentClassName: 'required-label-asterisk',
      autosize: { minRows: 2, maxRows: 8 },
      style: { padding: '11px', textAlign: 'left !important' },
      component: TextAreaField,
      maxLength: 120,
      extra: 'This is your chance to get Talent excited about your job offering. Focus on what the job offer is and what it offers to the Talent. Please make sure to review for grammar and spelling before submitting. Please try to keep this to one paragraph (max 120 characters)'
    },
    // ROW 4
    {
      layout: { span: 24 },
      name: 'description',
      label: 'Job description',
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
      component: HTMLEditorField,
      language: 'en',
      className: 'text-align-left border-radius-4',
      controls: ['bold', 'italic', 'underline', 'separator', 'list-ol', 'list-ul', 'link'],
      format: (value) => { console.log(); BraftEditor.createEditorState(value); },
      normalize: value => value && value.toHTML(),
      style: { border: '1px solid #d9d9d9', borderRadius: '20px', textAlign: 'left !important' },
      extra: 'Please describe the requirements for this position such as required work experience, qualities, skills, tasks involved. Mention what kind of documents applicants have to submit/send to you when applying for this position. Feel free to use this editor to make this post as nice as you can by adding lists, bold text, hyperlinked text, etc.',
    },
    // ROW 5
    {
      layout: { span: 24 },
      name: 'logo',
      label: 'Company logo',
      filename: 'companylogo.png',
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
      className: 'text-align-left',
      component: UploadField,
      listType: 'picture-card',
      accept: 'image/*',
      size: { width: 300, height: 300 },
    },
    // ROW 6
    // {
    //   layout: { sm: 24, md: 12},
    //   name: 'address',
    //   validate: [validate.required],
    //   label: 'Office address',
    //   className: 'text-align-left border-radius-4',
    //   style: { textAlign: 'left !important' },
    //   placeholder: 'Type the address of the job location',
    //   getPopupContainer: trigger => trigger.parentNode,
    //   component: InputField,
    // },
    // {
    //   layout: { sm: 24, md: 12},
    //   names: ['city', '_geoloc', 'country'],
    //   validate: validate.completeCity,
    //   label: 'City',
    //   className: 'text-align-left border-radius-4',
    //   style: { textAlign: 'left !important' },
    //   placeholder: 'Type the city name of the job location',
    //   component: GooglePlacesField,
    // },
    {
      layout: { sm: 24, md: 24},
      // names: ['city', '_geoloc', 'country'],
      names: ['address', 'city', '_geoloc', 'country'],
      validate: validate.completeCity,
      parentClassName: 'required-label-asterisk',
      // required: true,
      label: 'Location address',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      placeholder: 'Type the address of the job location',
      component: GooglePlacesField,
    },
    // ROW 7
    {
      layout: { span: 24 },
      name: 'how_apply',
      excludeFromForm: true,
      className: 'text-align-left',
      style: { textAlign: 'left !important' },
      component: <h2>How would you like people to apply for this position? (choose only one)</h2>,
      parentClassName: 'required-label-asterisk-desc',
    },
    // ROW 8
    {
      layout: { sm: 24, md: 12},
      name: 'contact_email',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      label: 'Via email',
      validate: [validate.requiredIfNoLink, validate.email],
      placeholder: 'Type your email address',
      component: InputField,
      // onChangeText: useEmail(true),
    },
    {
      layout: { sm: 24, md: 12},
      name: 'website_link',
      label: 'Via link to the official job offering',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      addonBefore: 'https://',
      validate: [validate.requiredIfNoEmail, validate.url],
      placeholder: 'Paste your link here',
      component: InputField,
      // onChange: useEmail(false),
      // disabled: useEmail(),
    },
    // ROW 9
    {
      layout: { span: 24 },
      name: 'hr',
      excludeFromForm: true,
      component: <hr />,
    },
    // ROW 10
    {
      layout: { span: 24 },
      name: 'description_header',
      excludeFromForm: true,
      className: 'text-align-left',
      style: { textAlign: 'left !important' },
      component: <h3>Fill in a short description of your company or organization here. This will be added to the end of your job offering description.</h3>,
    },
    // ROW 11
    {
      layout: { sm: 24, md: 24 },
      name: 'company_name',
      label: 'Company name',
      placeholder: 'Name of company',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
      component: InputField,
    }, {
      layout: { sm: 24, md: 24 },
      name: 'community',
      label: 'Your primary role in the innovation community',
      className: 'form-item-select text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
      placeholder: 'Select',
      getPopupContainer: trigger => trigger.parentNode,
      component: DropdownField,
      filterOption: (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0,
      children: getOptions([
        { key: 'Startup', text: 'Startup'},
        { key: 'Investor', text: 'Investor'},
        { key: 'Government', text: 'Government'},
        { key: 'Corporate', text: 'Corporate'},
        { key: 'Academia', text: 'Academia'},
        { key: 'Hub Partner', text: 'Hub Partner'},
        { key: 'Service Provider', text: 'Service Provider'},
        { key: 'Student', text: 'Student'},
        { key: 'Other', text: 'Other'},
      ]),
    },
    // ROW 12
    {
      layout: { span: 24 },
      name: 'short_company_description',
      label: 'Short company description',
      placeholder: 'Short company description',
      validate: [validate.required, validate.maxLength1000],
      parentClassName: 'required-label-asterisk',
      autosize: { minRows: 3, maxRows: 3 },
      className: 'text-align-left border-radius-4',
      style: { padding: '11px', textAlign: 'left !important' },
      component: TextAreaField,
      maxLength: 1000,
      extra: 'Fill in a short text (max 1000 characters) about your company or organization here. This will be added to the end of your event description.'
    },
    {
      layout: { sm: 24, md: 24 },
      name: 'org_contact',
      label: 'Full name (for internal use only)',
      placeholder: 'Main contact',
      validate: [validate.required],
      parentClassName: 'required-label-asterisk',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      component: InputField,
    },
    {
      layout: { sm: 24, md: 24 },
      name: 'email',
      label: 'Email (for internal use only)',
      validate: [validate.required, validate.email],
      parentClassName: 'required-label-asterisk',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      placeholder: 'Email address',
      component: InputField,
    },
    {
      layout: { sm: 24, md: 24 },
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      name: 'org_tel',
      validate: [validate.required, validate.phoneNumber],
      parentClassName: 'required-label-asterisk',
      label: 'Telephone (for internal use only)',
      placeholder: 'Telephone number',
      component: InputField,
    },
    {
      layout: { sm: 24, md: 24 },
      name: 'website',
      label: 'Company website',
      placeholder: 'URL',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      addonBefore: 'https://',
      component: InputField,
      validate: [validate.url],
    },
    {
      layout: { sm: 24, md: 24 },
      name: 'org_twitter',
      className: 'text-align-left border-radius-4',
      style: { textAlign: 'left !important' },
      addonBefore: '@',
      label: 'Twitter account',
      placeholder: 'Twitter account',
      component: InputField,
    },
  ];
}
