import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Button, Form, Input, Alert } from 'antd';
import firebase from '../../../firebase';

const FormItem = Form.Item;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      errorEmail: null,
      errorPass: null,
    };
  }

  static contextTypes = {
    router: PropTypes.object
  }

  onSubmit = (event) => {
    const { form } = this.props;

    form.validateFields((err, values) => {
      event.preventDefault();
      const {email, password} = values;
      var shouldSendRequest = true;

      if (email === undefined) {
        shouldSendRequest = false;
        this.setState({ errorEmail: 'Email is required' });
      } else if (email.replace(/\s/g, "") === "") {
        shouldSendRequest = false;
        this.setState({ errorEmail: 'Email is required' });
      } else {
        const re_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re_email.test(email)) {
          shouldSendRequest = false;
          this.setState({ errorEmail: 'Please enter a valid email' });
        }
      }

      if (password === undefined) {
        shouldSendRequest = false;
        this.setState({ errorPass: 'Password is required' });
      } else if (password.replace(/\s/g, "") === "") {
        shouldSendRequest = false;
        this.setState({ errorPass: 'Password is required' });
      }

      if (!shouldSendRequest){
        return;
      }

      this.setState({ loading: true });
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(result => {
          const currentUser = result.user;
          if (currentUser && currentUser.role !== 'user') {
            this.context.router.history.push('/admin/jobs');
            return;
          } else {
            this.context.router.history.push('/');
            return;
          }
        })
        .catch(error => this.setState({ error: error.message, loading: false, errorEmail: null, errorPass: null }));
    });

    return;
  }

  validateEmail = (rule, value, callback) => {
    const re_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value) {
      //check validation for email
      if (!re_email.test(value)) {
        this.setState({ errorEmail: 'Valid email is required' });
        callback('Valid email is required');
      }
    } else {
      this.setState({ errorEmail: 'Valid email is required' });
      callback('Valid email is required');
    }
    callback();
  }

  validatePassword = (rule, value, callback) => {
    if (value.replace(/\s/g, "") === "") {
      this.setState({ errorEmail: 'Password is required' });
      callback('Password is required');
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error }= this.state;

    return (
      <div>
        {error &&
          <Alert {...error} banner message={error} closable style={{ position: 'absolute' }} />
        }

        <Form className="formContainer" onSubmit={this.onSubmit}>
          <FormItem help={this.state.errorEmail} >
            {getFieldDecorator('email', {
              validateTrigger: 'onSubmit',
              rules: [{
                validator: this.validateEmailOrMobile
              }]
            })(
              <Input id="email" className="loginFormItem"
                style={this.state.errorEmail ? {borderColor: 'var(--accent-1)'} : {borderColor: 'var(--input-text-border)'}}
                onChange={() => this.state.errorEmail ? this.setState({errorEmail: null}) : ""}
                placeholder="Email" autoComplete="email"
              />
            )}
          </FormItem>

          <FormItem help={this.state.errorPass}>
            {getFieldDecorator('password', {
              validateTrigger: 'onSubmit',
            })(
              <Input id="password" type="password" className="loginFormItem"
                style={this.state.errorPass ? {borderColor: 'var(--accent-1)'} : {borderColor: 'var(--input-text-border)'}}
                onChange={() => this.state.errorPass ? this.setState({errorPass: null}) : ""}
                autoComplete="password" placeholder="Password"
              />
            )}
          </FormItem>

          <FormItem style={{ textAlign: 'center'}}>
            <Button type="primary" className="loginBtn" htmlType="submit">Sign in</Button>
          </FormItem>
        </Form>
      </div>
    )
  }
}

const enhancer = compose(
  firebaseConnect(),
  connect(),
  Form.create()
);

export default enhancer(LoginForm);
