import React from 'react';
import {
  Modal, Form, Input, Select, Button,
} from 'antd';
import * as R from 'ramda';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

export default Form.create({
  mapPropsToFields({ columns, record }) {
    if (!columns) {
      return {};
    }

    return columns.reduce((fields, item) => {
      fields[item.dataIndex] = Form.createFormField({
        ...item,
        value: R.pathOr('', [item.dataIndex], record),
      });
      return fields;
    }, {});
  },
})((props) => {
  const formItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const {
    form, form: { getFieldDecorator }, columns, show, handleOk, handleCancel, record, title, city, role,
  } = props;
  const validateForm = () => {
    form.validateFields((error, value) => {
      if (!error) {
        handleOk(value);
      }
    });
  };

  const getComponent = (component, placeholder, index) => {
    let children = [];

    if (index === 'city' || index === 'role') {
      Object.entries(R.pathOr({}, [], eval(index)))
        .map(item => children.push(
          <Option key={item[0]} value={index === 'city' ? item[1].description : item[1]}>{index === 'city' ? item[1].description : item[1]}</Option>,
        ));
    }

    if (component) {
      switch (component.type) {
        case 'Select':
          return (
            <Select
              showSearch
              placeholder={`Select a ${index}`}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {children}
            </Select>
          );
        case 'TextArea':
          return <TextArea placeholder={placeholder} style={{ borderRadius: '20px' }} />;
        default:
          return null;
      }
    }

    return <Input placeholder={placeholder} style={{ borderRadius: '20px', height: '40px' }} />;
  };

  return (
    <Modal
      title={`${record ? 'Edit' : 'Add'} ${title}`}
      visible={show}
      onOk={validateForm}
      onCancel={handleCancel}
      bodyStyle={{ maxWidth: '600px' }}
      footer={[
        <Button className="Modal_Cancel" key="back" onClick={handleCancel}>Cancel</Button>,
        <Button className="Modal_Save" key="submit" type="primary" onClick={validateForm}>Save</Button>,
      ]}
    >
      <Form>
        {columns.map((item) => {
          return (
            <FormItem
              {...formItemLayout}
              key={item.dataIndex}
            >
              {getFieldDecorator(item.dataIndex, {
                rules: item.rules,
              })(
                record && item.dataIndex === 'email'
                  ? <Input style={{ borderRadius: '20px', height: '40px' }} disabled />
                  : getComponent(item.component, item.title, item.dataIndex),
              )}
            </FormItem>
          );
        })}
      </Form>
    </Modal>
  );
});
