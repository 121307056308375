import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { helper, index } from '../../../algolia';
import './header.css';
import FilterBar from './filterBar';
import AddJobButton from './addJobButton';
import FeaturedList from './featuredList';

// const TalentPortalBg = 'https://venturecaferotterdam.org/wp-content/uploads/2018/08/TalentPortal_bg.png';
let windowWidth = window.innerWidth;

// eslint-disable-next-line react/prefer-stateless-function
export class Header extends Component {
  state = {
    // industries: [],
    // keywords: [],
    // employments: [],
    stickyHeader: false,
  }

  handleScroll = (event) => {
    // console.log('scroll height', event.target.scrollHeight);
    // let scrollHeight = 465;
    // if (windowWidth < 992) {
    //   scrollHeight = 338;
    // }
    let scrollHeight = 345;
    if (windowWidth < 992) {
      scrollHeight = 218;
    }
    // console.log('scroll', window.scrollY, window.pageYOffset, event.srcElement.scrollTop)
    if (!this.state.stickyHeader && event.target.className === "app" && event.target.scrollTop >= scrollHeight) {
      // console.log(true, event)
      this.setState({ stickyHeader: true })
    } else if (this.state.stickyHeader && event.target.className === "app" && event.srcElement.scrollTop < scrollHeight) {
      // console.log(false, event)
      this.setState({ stickyHeader: false })
    }
  }

  componentDidMount() {
  //   helper.on('result', (result) => {
  //     const industries = result.getFacetValues('industry');
  //     const keywords = result.getFacetValues('keywords');
  //     const employments = result.getFacetValues('employment');
  //     console.log({ industries, keywords, employments })

  //     this.setState({ industries, keywords, employments });
  //   });

  //   helper.search();
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  render() {
    const { stickyHeader } = this.state;
    const { currentCity, industries, keywords, employments } = this.props;
    return (
      <div className="header-placeholder">
        <div
          className={stickyHeader ? "header-background-sticky" : "header-background"}
          // className={"header-background"}
          style={{
            // backgroundImage: `url(${TalentPortalBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            // height: 345,
            color: 'white',
          }}
        >
          <div
            style={{
              backgroundColor: 'rgba(0,0,0,.6)',
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className={stickyHeader ? "header-container-sticky" : "header-container"}
            >
              <Row
                className={stickyHeader ? "header-hidden" : "headerRow"}
                // type={{ sm: 'block', md: 'flex' }}
                gutter={{ sm: 13.09, md: 30 }}
              >
                <Col
                  className="headerTitle"
                  xs={{ offset: 1, span: 22 }}
                  md={{ span: 6, offset: 1 }}
                  lg={{ span: 6, offset: 1 }}
                >
                  Talent Portal
                </Col>
                <Col
                  className="headerSubtitle"
                  xs={{ offset: 1, span: 22 }}
                  md={{ span: 14, offset: 1 }}
                  lg={{ span: 14, offset: 1 }}
                >
                  The Venture Café Network is where talent and opportunities connect. Share and explore the possibilities. <a style={{ display: 'none' }} href={"https://venturecaferotterdam.org/who-we-are/"} className="colorPrimary">Learn More</a>
                </Col>
              </Row>
              <Row gutter={{ sm: 13.09, md: 30 }} type="flex" justify="space-between" className="filterBarRow">
                <Col xs={{ offset: 1, span: 22 }} sm={{ offset: 1, span: 22 }} md={{ offset: 2, span: 15 }}>
                  <FilterBar trigger={this.props.trigger} triggerList={this.props.triggerList} onChange={this.props.onChange} industries={industries} employments={employments} keywords={keywords} />
                </Col>
                <Col xs={2} sm={2} md={{ offset: 0, span: 5, pull: 2 }} style={{ paddingRight: 0 }}>
                  <AddJobButton currentCity={currentCity} industries={industries} employments={employments} keywords={keywords} />
                </Col>
              </Row>
              <Row className={stickyHeader ? "header-hidden" : "featuredRow"}>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }} lg={{ span: 24 }} >
                  <FeaturedList currentCity={currentCity} data={this.props.data} dataLength={this.props.dataLength} onChange={this.props.onChange}/>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(Header);
