import React from 'react'
import { Card, Icon, Avatar } from 'antd'
import { Link } from 'react-router-dom'

const { Meta } = Card;

const EventCard = (props) => (
  <Card
    style={{ width: 300 }}
    cover={<img alt="example" src={`https://picsum.photos/200/300/?random=${props.index}`} />}
    actions={[<Icon type="setting" />, <Link to={`/event/${props.index}`}><Icon type="edit" /></Link>, <Icon type="ellipsis" />]}
  >
    <Meta
      avatar={<Avatar src={`http://collegebowl.avatarpro.biz/avatar.png?random=${props.index}`} />}
      title={props.title}
      description="This is the description"
    />
  </Card>
);

export default EventCard;
