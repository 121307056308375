import './test.css'
import React, { Component } from "react"
import { connect } from 'react-redux'

export class Test extends Component {
    render() {
        return (
            <div className="test">
                <h1>TEST</h1>
            </div>
        )
    }
}

export default connect(null, null)(Test)
