import React, { Component } from 'react';
import { Parser as HtmlToReactParser } from 'html-to-react';
import Truncate from 'react-truncate';
import moment from 'moment';
import { Row, Col } from 'antd';

const htmlToReactParser = new HtmlToReactParser();
const getHTML = (htmlInput) => {
  const reactElement = htmlToReactParser.parse(htmlInput);
  return (
    <Truncate lines={4} ellipsis={<span>... <a href={null} className="see-more">See More</a></span>}>
      {reactElement}
    </Truncate>
  );
};

export default class CommunityCard extends Component {

  render() {
    const { data, sortPublish, openJobModal } = this.props;
    const { title, logo, employment, short_description, description, country, city, industry, publicationDate, startDate } = data;
    // console.log({data})
    const publish = (publicationDate && publicationDate._seconds) ? moment(parseInt((publicationDate._seconds * 1000))).fromNow() : null;
    const start = (startDate && startDate._seconds) ? moment(parseInt((startDate._seconds * 1000))).fromNow() : null;

    let shortDesc = getHTML(short_description ? short_description : description);

    return (
      <div
        className="communityCardBody"
        onClick={() => openJobModal({ openJobOverview: true, selectedJob: data })}
      >
        <Row gutter={10} onClick={() => openJobModal({ openJobOverview: true, selectedJob: data })}>
          <Col xs={24} sm={14}>
            <div className={data.featured === 1 ? "communityCardMain communityCardMain_border" : "communityCardMain"}>
              <Row style={{ height: '60px' }}>
                <Col xs={{ span: 6 }} md={{ span: 8 }}>
                  <img src={logo} alt={title} style={{ height: '60px', width: '60px', marginRight: '15px', objectFit: 'contain' }} />
                </Col>
                <Col xs={{ span: 18 }} md={16} style={{ fontSize: '16px', fontWeight: '200', lineHeight: '19px', display: 'flex', alignItems: 'center', height: '100%' }}>
                  {data.organization ? data.organization : data.company_name}
                </Col>
              </Row>
              <div className="communityCardMain_title">
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {title}
                </Truncate>
              </div>
              <div className="communityCardMain_shortDesc">
                {shortDesc}
              </div>
            </div>
          </Col>

          <Col xs={24} sm={10}>
            <div className="communityCardSide">
              <div>
                <div className="communityCardSide_based">
                  BASED IN
                  <div className="communityCardSide_city">
                    {country}
                    <br />
                    {city}
                  </div>
                </div>
                <div className="communityCardSide_industry">
                  INDUSTRY
                  <div className="communityCardSide_industryList">
                    {industry.map((item, index) => {
                      if (index < (industry.length - 1)) {
                        return item + ', ';
                      }
                      return item;
                    })}
                  </div>
                </div>
                <div className="communityCardSide_employment">
                  EMPLOYMENT
                  <div className="communityCardSide_employmentType">
                    {employment}
                  </div>
                </div>
              </div>
              <div className="communityCardSide_sortDate">
                {sortPublish ? publish : start}
              </div>
            </div>
          </Col>

        </Row>
      </div>
    );
  }
}
