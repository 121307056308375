import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Icon, Checkbox, Drawer } from 'antd';
import { FiChevronDown } from 'react-icons/fi';

import './filterBar.css';

const windowWidth = window.innerWidth;
// console.log({windowWidth})

// eslint-disable-next-line react/prefer-stateless-function
export class FilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      industryDropDown: false,
      industrySelected: [],
      employmentDropDown: false,
      employmentSelected: null,
      keywordDropDown: false,
      keywordSelected: null,
      placement: 'bottom',
    };
  }

  // componentDidMount() {
  //   const { industries, keywords, employments } = this.props;
  //   this.setState({ industries, keywords, employments });
  // }

  closeModal() {
    this.setState({ openModal: false, industryDropDown: false, keywordDropDown: false, employmentDropDown: false });
  }

  keywordModal() {
    const { openModal, keywordDropDown } = this.state;
    const { keywords } = this.props;

    if (windowWidth < 992) {
      return (
        <Drawer
          // title="Basic Drawer"
          placement={'bottom'}
          closable={false}
          onClose={() => this.closeModal()}
          visible={true}
          mask={false}
          maskStyle={{
            height: 0,
            // overflow: 'scroll'
          }}
          style={{
            overflow: 'scroll',
          }}
          bodyStyle={{
            overflow: 'scroll',
          }}
          zIndex={3}
        >
          <div
            style={{
              borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
              // height: '33px',
              paddingBottom: 10,
              cursor: 'pointer',
            }}
            onClick={() => this.onChangeKeyword('')}
          >Any</div>
          {keywords.map((item,index) => {
            const key = item.name.replace(/([^a-z])+/gi, '');
            return (
              <div
                key={key}
                style={{
                  borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
                  // height: '33px',
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontWeight: '200',
                  fontSize: '13px',
                  lineHeight: '16px',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={() => this.onChangeKeyword(item.name)}
              >
                <div>{item.name}</div>
                {/*<div>{item.name} [{item.count}]</div>*/}
              </div>
            );
          })}
        </Drawer>
      )
    }

    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          marginTop: 10,
          zIndex: 2,
          color: '#434343',
          height: '260px',
          width: '196px',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
          padding: '18px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'scroll',
          boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.12)',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
            // height: '33px',
            paddingBottom: 10,
            cursor: 'pointer',
          }}
          onClick={() => this.onChangeKeyword('')}
        >Any</div>
        {keywords.map((item,index) => {
          const key = item.name.replace(/([^a-z])+/gi, '');
          return (
            <div
              key={key}
              style={{
                borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
                // height: '33px',
                paddingTop: 10,
                paddingBottom: 10,
                fontWeight: '200',
                fontSize: '13px',
                lineHeight: '16px',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => this.onChangeKeyword(item.name)}
            >
              <div>{item.name}</div>
              {/*<div>{item.name} [{item.count}]</div>*/}
            </div>
          );
        })}
      </div>
    );
  }

  onChangeKeyword(checkedValues) {
    // console.log('checked = ', checkedValues);
    this.setState({ keywordSelected: checkedValues }, this.onChange);
    this.closeModal()
    // console.log(this.state.keywordSelected)
  }

  openKeywordList() {
    // console.log('openKeywordList');
    this.setState({
      keywordDropDown: !this.state.keywordDropDown,
      employmentDropDown: false,
      industryDropDown: false
    });

  }

  employmentModal() {
    const { openModal, employmentDropDown } = this.state;
    const { employments } = this.props;

    if (windowWidth < 992) {
      return (
        <Drawer
          // title="Basic Drawer"
          placement={'bottom'}
          closable={false}
          onClose={() => this.closeModal()}
          visible={true}
          mask={false}
          maskStyle={{
            height: 0,
            // overflow: 'scroll'
          }}
          style={{
            overflow: 'scroll',
          }}
          bodyStyle={{
            overflow: 'scroll',
          }}
          zIndex={3}
        >
          <div
            style={{
              borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
              // height: '33px',
              paddingBottom: 10,
              cursor: 'pointer',
            }}
            onClick={() => this.onChangeEmployment('')}
          >Any</div>
          {employments.map((item) => {
            const key = item.name.replace(/([^a-z])+/gi, '');
            return (
              <div
                key={key}
                style={{
                  borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
                  // height: '33px',
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontWeight: '200',
                  fontSize: '13px',
                  lineHeight: '16px',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={() => this.onChangeEmployment(item.name)}
              >
                <div>{item.name}</div>
                {/*<div>{item.name} [{item.count}]</div>*/}
              </div>
            );
          })}
        </Drawer>
      )
    }

    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          // left: 206,
          marginLeft: '206px',
          marginTop: 10,
          zIndex: 2,
          color: '#434343',
          height: '260px',
          width: '216px',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
          padding: '18px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'scroll',
          boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.12)',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
            // height: '33px',
            paddingBottom: 10,
            cursor: 'pointer',
          }}
          onClick={() => this.onChangeEmployment('')}
        >Any</div>
        {employments.map((item) => {
          const key = item.name.replace(/([^a-z])+/gi, '');
          return (
            <div
              key={key}
              style={{
                borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
                // height: '33px',
                paddingTop: 10,
                paddingBottom: 10,
                fontWeight: '200',
                fontSize: '13px',
                lineHeight: '16px',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => this.onChangeEmployment(item.name)}
            >
              <div>{item.name}</div>
              {/*<div>{item.name} [{item.count}]</div>*/}
            </div>
          );
        })}
      </div>
    );
  }

  onChangeEmployment(checkedValues) {
    // console.log('checked = ', checkedValues);
    this.setState({ employmentSelected: checkedValues }, this.onChange);
    this.closeModal()
    // console.log(this.state.employmentSelected)
  }

  openEmploymentList() {
    // console.log('openEmploymentList');
    this.setState({
      employmentDropDown: !this.state.employmentDropDown,
      keywordDropDown: false,
      industryDropDown: false
    });

  }

  industryModal() {
    const { openModal, industryDropDown, industrySelected } = this.state;
    const { industries } = this.props;

    if (windowWidth < 992) {
      return (
        <Drawer
          // title="Basic Drawer"
          placement={'bottom'}
          closable={false}
          onClose={() => this.closeModal()}
          visible={true}
          mask={false}
          maskStyle={{
            height: 0,
            // overflow: 'scroll'
          }}
          style={{
            overflow: 'scroll',
          }}
          bodyStyle={{
            overflow: 'scroll',
          }}
          zIndex={3}
        >
          <div
            style={{
              borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
              // height: '33px',
              paddingBottom: 10,
              cursor: 'pointer',
            }}
            onClick={() => this.onChangeIndustry('')}
          >Any</div>
          {industries.map((item, index) => {
            const key = item.name.replace(/([^a-z])+/gi, '');
            return (
              <div
                key={key}
                style={{
                  borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
                  // height: '33px',
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontWeight: '200',
                  fontSize: '13px',
                  lineHeight: '16px',
                  width: '100%',
                  cursor: 'pointer',
                }}
              >
                <Checkbox checked={industrySelected.includes(item.name)} onChange={(e) => this.onChangeIndustry(e.target.value)} value={item.name}>
                  {item.name}
                </Checkbox>
              </div>
            );
          })}
        </Drawer>
      )
    }

    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          // right: 0,
          marginLeft: '432px',
          marginTop: 10,
          zIndex: 2,
          color: '#434343',
          height: '260px',
          width: '251px',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
          padding: '18px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'scroll',
          boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.12)',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
            // height: '33px',
            paddingBottom: 10,
            cursor: 'pointer',
          }}
          onClick={() => this.onChangeIndustry('')}
        >Any</div>
        {industries.map((item, index) => {
          const key = item.name.replace(/([^a-z])+/gi, '');
          return (
            <div
              key={key}
              style={{
                borderBottom: '1px solid rgb(67, 67, 67, 0.1)',
                // height: '33px',
                paddingTop: 10,
                paddingBottom: 10,
                fontWeight: '200',
                fontSize: '13px',
                lineHeight: '16px',
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <Checkbox checked={industrySelected.includes(item.name)} onChange={(e) => this.onChangeIndustry(e.target.value)} value={item.name}>
                {item.name}
              </Checkbox>
            </div>
          );
        })}
      </div>
    );
  }

  onChangeIndustry(checkedValues) {
    // console.log('checked = ', checkedValues);
    let list;
    if (checkedValues === '') {
      list = [];
      this.setState({ industrySelected: list, industryDropDown: false }, this.onChange);
    } else {
      list = this.state.industrySelected;
      if (list.includes(checkedValues)) {
        list = list.filter(item => item !== checkedValues);
      } else {
        list.push(checkedValues);
      }
      this.setState({ industrySelected: list }, this.onChange);
    }
    // console.log(this.state.industrySelected)
  }

  openIndustryList() {
    // console.log('openIndustryList');
    this.setState({
      industryDropDown: !this.state.industryDropDown,
      keywordDropDown: false,
      employmentDropDown: false
    });

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.employmentSelected !== this.state.employmentSelected ||
      prevState.industrySelected !== this.state.industrySelected ||
      prevState.keywordSelected !== this.state.keywordSelected) {
      this.props.triggerList(!this.props.trigger);
    }
  }

  onChange = () => {
    const { employmentSelected, industrySelected, keywordSelected } = this.state;
    const { onChange } = this.props;

    const keywordFilters = keywordSelected && [`keywords:${keywordSelected}`];
    const employmentFilters = employmentSelected && [`employment:${employmentSelected}`];
    const industryFilters = industrySelected.length > 0 && industrySelected.map(i => `industry:${i}`)

    const facetFilters = [];
    if (keywordFilters) facetFilters.push(keywordFilters);
    if (employmentFilters) facetFilters.push(employmentFilters);
    if (industryFilters) facetFilters.push(industryFilters);

    onChange({ facetFilters }, true, 'filter');
  }

  render() {
    const { openModal, industryDropDown, employmentDropDown, keywordDropDown, industrySelected, keywordSelected, employmentSelected } = this.state;
    return (
      <div style={{ width: '100%' }}>
        {(industryDropDown || keywordDropDown || employmentDropDown) && (
          <div
            style={{
              height: '100vh',
              width: '100vw',
              background: 'rgba(0,0,0,0.5)',
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
            onClick={() => this.closeModal()}
          />
        )}
        <Row
          className="filterBar-container"
          style={{ position: 'relative', zIndex: 2 }}
          gutter={{ sm: 13.09, md: 30 }}
        >
          <Col xs={{ span: 8 }} md={{ span: 8 }} style={{ height: '100%' }}>
            <button type="button" className="btnFilter" onClick={() => this.openKeywordList()}>
              <div style={{ textAlign: 'left' }}>Function
                {keywordSelected !== null && (
                  <div className="filterSelected colorPrimary" style={{ fontSize: '14px', fontWeight: '200' }}>{keywordSelected}</div>
                )}
              </div>
              <FiChevronDown className="btnFilterIcon" />
            </button>
          </Col>
          <Col xs={{ span: 8 }} md={{ span: 8 }} style={{ height: '100%' }}>
            <button type="button" className="btnFilter" style={{ justifyContent: 'center' }} onClick={() => this.openEmploymentList()}>
              <div style={{ textAlign: 'left' }}>Employment
                {employmentSelected !== null && (
                  <div className="filterSelected colorPrimary" style={{ fontSize: '14px', fontWeight: '200' }}>{employmentSelected}</div>
                )}
              </div>
              <FiChevronDown className="btnFilterIcon" />
            </button>
          </Col>
          <Col xs={{ span: 8 }} md={{ span: 8 }} style={{ height: '100%' }}>
            <button type="button" className="btnFilter" style={{ justifyContent: 'flex-end' }} onClick={() => this.openIndustryList()}>
              <div style={{ textAlign: 'left' }}>Industry
                {industrySelected !== [] && (
                  <div className="filterSelected colorPrimary" style={{ fontSize: '14px', fontWeight: '200' }}>{industrySelected[0]} {industrySelected.length > 1 && (' +' + (industrySelected.length - 1))}</div>
                )}
              </div>
              <FiChevronDown className="btnFilterIcon" />
            </button>
          </Col>
        </Row>
        {(industryDropDown) && this.industryModal()}
        {(employmentDropDown) && this.employmentModal()}
        {(keywordDropDown) && this.keywordModal()}
      </div>
    );
  }
}

export default connect(null, null)(FilterBar);
