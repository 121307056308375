import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Menu, Dropdown, Icon, Modal, Input, Tag, Button, Tooltip } from 'antd';
import { FiPlus } from 'react-icons/fi';
import { Parser as HtmlToReactParser } from 'html-to-react';

const htmlToReactParser = new HtmlToReactParser();
const getHTML = (htmlInput) => {
  const reactElement = htmlToReactParser.parse(htmlInput);
  return reactElement;
}

// import './jobOverview.css';

// eslint-disable-next-line react/prefer-stateless-function
export class JobOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { data, filterCompany } = this.props;
    // const useless = null;
    // const moreUseless = null;
    console.log('jobOverview', data);
    let tempPublishDate;
    let tempStartDate;
    if (data.publicationDate) {
      tempPublishDate = data.publicationDate._seconds || data.publicationDate.seconds;
      tempStartDate = data.startDate._seconds || data.startDate.seconds;
    }
    if (!tempPublishDate) {
      tempPublishDate = data.publicationDate;
    }
    if (!tempStartDate) {
      tempStartDate = data.startDate/1000;
    }
    // let tempDate = 1;
    const publishDate = new Date(tempPublishDate * 1000);
    const startDate = new Date(tempStartDate * 1000);
    // console.log(tempDate);
    // console.log('publishDate', tempPublishDate, publishDate , publishDate.toUTCString());
    // console.log('startDate', tempStartDate, startDate, startDate.toUTCString());

    const keywords = Array.isArray(data.keywords) ? data.keywords : [data.keywords];
    // console.log({keywords})
    const industry = Array.isArray(data.industry) ? data.industry : [data.industry];

    let salaryTier = '';
    switch (data.salary_tier) {
      case 1:
        salaryTier = 'less than 2000';
        break;
      case 2:
        salaryTier = '2000 < ... < 3000';
        break;
      case 3:
        salaryTier = '3000 < ... < 4000';
        break;
      case 4:
        salaryTier = '4000 < ... < 5000';
        break;
      case 5:
        salaryTier = '5000 < ... < 6000';
        break;
      case 6:
        salaryTier = 'more than 6000';
        break;
      default:
        // salaryTier = '';
        salaryTier = data.salary_tier;
        break;
    }

    return (
      <div
        style={{
          padding: '20px',
        }}
      >
        <div
          style={{
            fontSize: '40px',
            fontWeight: '500',
            textAlign: 'center',
            lineHeight: 'normal',
          }}
        >
          {data.title}
          <div style={{ fontSize: '20px', fontWeight: '300' }}>
            {data.city}, {data.country}
          </div>
        </div>
        <div style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>
          <img
            src={data.logo}
            alt="logo"
            style={{
              height: '300px',
              width: '300px',
              maxWidth: '60vw',
              textAlign: 'center',
              objectFit: 'contain',
            }}
          />
        </div>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
          >
            <div style={{ marginTop: '30px' }}>
              <strong className="colorPrimary"><Icon type="team" /> EMPLOYMENT TYPE</strong>
              <div>
                <Tag color={'white'}>{data.employment}</Tag>
              </div>
            </div>
          </Col>
          {(keywords && keywords[0]) && (
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
            >
              <div style={{ marginTop: '30px' }}>
                <strong className="colorPrimary"><Icon type="radar-chart" /> FUNCTION</strong>
                <div>
                  {keywords.map((item,index) => (
                    <Tag key={index} color={'white'}>{item}</Tag>
                  ))}
                </div>
              </div>
            </Col>
          )}
          {industry && (
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
            >
              <div style={{ marginTop: '30px' }}>
                <strong className="colorPrimary"><Icon type="project" /> INDUSTRY</strong>
                <div>
                  {industry.map((item,index) => (
                    <Tag key={index} color={'white'}>{item}</Tag>
                  ))}
                </div>
              </div>
            </Col>
          )}
        </Row>
        <div style={{ marginTop: '30px' }}>
          <strong className="colorPrimary"><Icon type="calendar" /> START DATE</strong>
          <div>
            {startDate.toLocaleDateString()}
          </div>
        </div>
        {data.show_salary && (
          <div style={{ marginTop: '30px' }}>
            <strong className="colorPrimary"><Icon type="safety" /> SALARY TIER</strong>
            <div>
              {salaryTier}
            </div>
          </div>
        )}
        <div style={{ marginTop: '30px' }}>
          <strong className="colorPrimary"><Icon type="profile" /> JOB DESCRIPTION</strong>
          {getHTML(data.description)}
        </div>
        <div style={{ marginTop: '30px' }}>
          <strong className="colorPrimary"><Icon type="global" /> COMPANY</strong>
          <div>
            <Tooltip title="See other jobs from this company">
              <Button style={{ border: 'none' }} key="company_name" type="primary" onClick={() => filterCompany(data.company_name)}>
                {data.organization ? data.organization : data.company_name}
              </Button>
            </Tooltip>
          </div>
        </div>
        {data.short_company_description && (
          <div style={{ marginTop: '30px' }}>
            <strong className="colorPrimary"><Icon type="solution" /> ABOUT COMPANY</strong>
            <div>
              {getHTML(data.short_company_description)}
              {data.website && data.website}
            </div>
          </div>
        )}
        {data.address && (
          <div style={{ marginTop: '30px' }}>
            <strong className="colorPrimary"><Icon type="environment" /> ADDRESS</strong>
            <div>
              {data.address}
            </div>
          </div>
        )}
        {data.contact_email && (
          <div style={{ marginTop: '30px' }}>
            <strong className="colorPrimary"><Icon type="mail" /> CONTACT EMAIL</strong>
            <div>
              {data.contact_email}
            </div>
          </div>
        )}
        <div style={{ marginTop: '30px' }}>
          <strong className="colorPrimary"><Icon type="calendar" /> PUBLICATION DATE</strong>
          <div>
            {publishDate.toLocaleDateString()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(JobOverview);
