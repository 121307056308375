import { Input, Upload } from 'antd';
import { render, renderUpload, renderConnected } from '../../../utils/form';
import * as validate from '../validate';
import CitySearchInput from '../../../components/common-ui/CitySearchInput';

const InputField = render(Input);
const TextAreaField = render(Input.TextArea);
const UploadField = renderUpload(Upload);
// const GooglePlacesField = renderConnected(LocationSearchInput);
const GooglePlacesField = renderConnected(CitySearchInput);

export default function fieldConfig() {
  return [
    // ROW 1
    {
      layout: { sm: 24, md: 8 },
      name: 'logo',
      label: 'Company logo',
      filename: 'companylogo.png',
      validate: [validate.required],
      component: UploadField,
      listType: 'picture-card',
      accept: 'image/*',
      size: { width: 300, height: 300 },
    },
    // ROW 2
    {
      layout: { sm: 24, md: 8 },
      name: 'site_color',
      excludeFromForm: true,
    },
    // ROW 3
    {
      layout: { sm: 24, md: 8 },
      name: 'background_image',
      label: 'Background Image',
      filename: 'companylogo.png',
      validate: [validate.required],
      component: UploadField,
      listType: 'picture-card',
      accept: 'image/*',
      size: { width: 1920, height: 345 },
    },
    // ROW 4
    {
      layout: { span: 24 },
      names: ['city', '_geoloc', 'country'],
      validate: validate.completeCity,
      label: 'City',
      placeholder: 'Type the city name of the job location',
      component: GooglePlacesField,
    },
    // ROW 6
    {
      layout: { sm: 24 },
      name: 'slug',
      label: 'Short Name',
      placeholder: 'Short Name for URL',
      normalize: value => value && value.toLowerCase(),
      component: InputField,
      validate: [validate.required],
    },
    // ROW 7
    {
      layout: { span: 24 },
      name: 'description',
      label: 'Description',
      placeholder: 'Description',
      validate: [validate.required, validate.maxLength70],
      autosize: { minRows: 3, maxRows: 6 },
      component: TextAreaField,
    },
  ];
}
